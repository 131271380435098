import React from 'react'
import SectionTitle from '../Ui/SectionTitle'
import { getHoursWith2023Format } from '../../utils/formattedDate'
import { getFormattedDayOfWeek } from '../Utils/DateTime'

const Appearances = ({ data, isFree }) => {
  const { lpdv_id: lpdvID, stage } = data.event.document.data
  const { title } = stage.document.data.place.document.data
  const appearances = []

  data.event.document.event_appearances.data.allPrismicAppearances.edges.forEach(
    ({ node }) => {
      appearances.push(node.data)
    }
  )

  const getDateTime = (i = 0) => {
    const appearance = appearances.sort((a, b) => new Date(a.time) - new Date(b.time))[i]
    const dateTime = appearance.time ? new Date(appearance.time) : new Date(data.event.document.data.start_time_raw)
    return dateTime
  }

  return (
    <div className='mx-0 w-full pt-4'>
      <SectionTitle className={'font-obviously font-black uppercase text-yellow-wolf2024 tracking-wide text-center text-2xl'}>
        {getFormattedDayOfWeek(getDateTime())}
      </SectionTitle>
      <div className="font-obviously font-medium italic text-yellow-wolf2024 tracking-wider text-md mb-1">
        {title
          ? (
          <a href="/infos/carte#info_content" >
            {title.text}
          </a>
            )
          : null}
      </div>
      <div className="font-obviously font-medium italic text-white text-md text-center tracking-tight">
        <ul className="list-none mb-0 py-1">
          {appearances.length > 0 &&
            appearances.map((appearance, i) => {
              return (
                <li className="mt-0" key={i}>
                  <span className="lowercase">
                    {getHoursWith2023Format(getDateTime(i))}
                    {' • '}
                  </span>
                  <a href={'/artistes/' + appearance.artist.document.slug}
                      className="text-center hover:underline">
                    {appearance.artist.document.data.title.text}
                  </a>
                </li>
              )
            })}
        </ul>
      </div>
    </div>
  )
}

export default Appearances
